<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs">
      <template v-slot:button>
        <v-btn @click="create = true" height="42" width="147" color="primary" class="rounded-pill mr-6" style="text-transform: none !important">
          Crear codigo
        </v-btn>
      </template>
    </BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheCodeDataTable />
      </v-col>
    </v-row>
    <TheCreateCode @close="create=false" v-model="create"></TheCreateCode>
  </v-container>
</template>

<script>
export default {
  // name: "Codes",
  components: {
    TheCodeDataTable: () =>
      import(
        "@/components/dashboardComponents/TheCodeDataTable"
      ),
    TheCreateCode: () =>
      import(
        "@/components/dashboardComponents/TheCreateCode"
      )
  },
  data: () => ({
    reload: false,
    create: false,
    page: {
      title: "Códigos"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        to: "/dashboard/index/"
      },
      {
        text: "Códigos",
        disabled: true
      }
    ]
  })
};
</script>
